import { EnvironmentConfig } from './environment.type';

export const environment: EnvironmentConfig = {
  production: true,
  name: 'Production',
  version: '1.8.6 (0001)',
  // releaseDate: 'N/A',
  enableLogger: false,
  enablePWA: false,
  apiPath: 'https://app.stickyqr.com',
  apiEmbedPath: 'https://embed.stickyqr.com',
  apiBusinessPath: '',
  ablyKey: 'f1q7yg.f80baA:LKl1-gkcKFUQv5oTaohYpjWYDQFOukUHubsC2ILuIB4',
  stripeKey: 'pk_live_51NOu1RDQMUAIP6GQy8ERe4wylFpFgFL7mf5Y9uaBAozOflVetdfm5gIztGXR53JrXVeC5ubm4bT0fBHdZCYWLuBE00ARcXr5sw',
  cdn: 'https://cdn.stickyqr.com/',
  sentry: {
    dsn:'https://00b030b651320db5607910da7d3e1ef0@o4505979655553024.ingest.sentry.io/4506787858415616',
  },
  stickyqrURL:'https://www.stickyqr.com'
};
